import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
        {element}
        <Script 
            key="script-id"  
            strategy="idle"
            onLoad={() => console.log("SCRIPT LOADED")}
            onError={() =>  {
              var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true;
              se.src = "https://torage.googleapis.com/moneypennychat/js/a8614a83-4d3d-451c-ab4f-3312e715f17d.js";
              var done = false;
              se.onload = se.onreadystatechange = function() {
                if (!done&&(!this.readyState||this.readyState==='loaded'||this.readyState==='complete')) {
                  done = true;
                  /* Place your Moneypenny JS API code below */
                  /* Moneypenny.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
                }
              };
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
            }}>
        </Script>
    </>
  )
}