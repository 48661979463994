import { createSlice } from '@reduxjs/toolkit'

const commonState = createSlice({
	name: 'commonState',
	initialState: {
		googleScriptLoaded : false,
		loading: false,
		miscellaneousData: {}
	},
	reducers: {
		updateGoogleScriptState: (state, action) => {
			const isLoaded = action.payload;
			state.googleScriptLoaded = isLoaded
		},
		// updateMorgateGatedFormState: (sate, action) => {
			
		// }
	}
})

export const { updateGoogleScriptState } = commonState.actions

export default commonState.reducer;
