
import { configureStore,combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import common from '../redux/slice/common';

const reducers = combineReducers({
	common : common
});

const persistConfig = {
	key: 'nicolRoot',
	storage,
	timeout: null,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
});

export default store;